import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {ConfirmDialog} from "primereact";
import {BookingsService} from "../../service/BookingsService";
import {TabPanel, TabView} from "primereact/tabview";
import {IncomeTab} from "./tabs/IncomeTab";
import {AttachTab} from "./tabs/AttachTab";
import {CostTab} from "./tabs/CostTab";
import {InfoTab} from "./tabs/InfoTab";
import {Button} from "primereact/button";
import {StatusService} from "../../service/StatusService";
import {TransportModeService} from "../../service/TransportModeService";
import {IncotermsService} from "../../service/IncotermsService";
import {CountryDirectionService} from "../../service/CountryDirectionService";
import {ShippingLinesService} from "../../service/ShippingLinesService";
import {TradeTypesService} from "../../service/TradeTypesService";
import {Toast} from "primereact/toast";
import {ContainerTypesService} from "../../service/ContainerTypesService";
import nextId, {setPrefix} from "react-id-generator";
import {isNullOrUndef, isObject} from "chart.js/helpers";
import moment from "moment";
import {isEmpty} from "lodash";
import {AuditLog} from "../../components/AuditLog";
import {Dialog} from "primereact/dialog";
import {BookingTransferModule} from "./modules/BookingTransferModule";
import {ClientGroupsService} from "../../service/ClientGroupsService";
import {confirmDialog} from "primereact/confirmdialog";

export const Booking = (props) => {

    const [load, setLoad] = useState(false)
    const [edit, setEdit] = useState(false)
    const [booking, setBooking] = useState(null)
    const [statuses, setStatues] = useState([])
    const [incoterms, setIncoterms] = useState([]);
    const [transportModes, setTransportModes] = useState([]);
    const [directions, setDirections] = useState([]);
    const [tradeTypes, setTradeTypes] = useState([]);
    const [shippingLines, setShippingLines] = useState([]);
    const [containerTypes, setContainerTypes] = useState([]);
    const [validForm, setValidForm] = useState(false);
    const [activatedEdit, setActivatedEdit] = useState(false);
    const [billOfLadingStatuses, setBillOfLadingStatuses] = useState([]);
    const [importerSecurityFillings, setImporterSecurityFillings] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [statusOptions, setStatusOptions] = useState([]);
    const [podUpdateRegister, setPodUpdateRegister] = useState(null);
    const [checkSum, setCheckSum] = useState('');
    const [bookingAuditData, setBookingAuditData] = useState(null);
    const [bookingAuditDialogVisible, setBookingAuditDialogVisible] = useState(false);
    const [bookingTransferDialogVisible, setBookingTransferDialogVisible] = useState(false);
    const [actionTransfer, setActionTransfer] = useState(true);
    const [bookingTransferDialogHeader, setBookingTransferDialogHeader] = useState("");
    const [isClosed, setIsClosed] = useState(false);
    const [isClosedIncomeCostTab, setIsClosedIncomeCostTab] = useState(false);
    const [transferring, setTransferring] = useState(false);
    const [bookingClientIsEmpty, setBookingClientIsEmpty] = useState(false);
    const [bookingDirectionIsEmpty, setBookingDirectionIsEmpty] = useState(false);
    const [bookingIncotermsIsEmpty, setBookingIncotermsIsEmpty] = useState(false);
    const [checkManagerError, setCheckManagerError] = useState(false);
    const [checkCostInvoices, setCheckCostInvoices] = useState(true);
    const [requiredAndDisabledFields, setRequiredAndDisabledFields] = useState({});
    const [errFields, setErrFields] = useState([]);
    const bookingsService = new BookingsService();
    const statusService = new StatusService();
    const transportModeService = new TransportModeService();
    const incotermsService = new IncotermsService();
    const countryDirectionService = new CountryDirectionService();
    const shippingLineService = new ShippingLinesService();
    const tradeTypeService = new TradeTypesService();
    const containerTypesService = new ContainerTypesService();
    const clientGroupsService = new ClientGroupsService();

    let {paramBookingId, paramTab} = useParams();
    const toast = useRef(null);
    let history = useHistory();
    const JSum = require('jsum')
    setPrefix("temp-");

    let emptyBooking = {
        bookingId: '',
        billOfLadingStatus: null,
        cargoItems: [],
        cargos: [],
        confirmedAt: null,
        consignee: null,
        client: null,
        costs: [],
        costsPlan: 0,
        costsReal: 0,
        createdAt: null,
        customerRefId: '',
        customsClearance: false,
        cutOffDate: null,
        directionId: null,
        forwarder: null,
        id: null,
        incomes: [],
        incomesPlan: 0,
        incomesReal: 0,
        incotermId: null,
        insurance: false,
        importerSecurityFilling: null,
        kipis: false,
        number: '',
        placeOfDischarge: null,
        placeOfLoading: null,
        plan: 0,
        portOfDischarge: null,
        portOfDischargeArrival: null,
        portOfDischargeArrivalNotice: null,
        portOfLoading: null,
        portOfLoadingDeparture: null,
        real: 0,
        remarks: '',
        shipper: null,
        shippingInstruction: false,
        shippingLineId: null,
        statusId: 2,
        tradeTypeId: null,
        transportModeId: null,
        updatedAt: null,
        user: null,
        verifiedGrossMass: false,
        proofOfDelivery: false,
        mhPc: false,
        mhOc: false,
        operationalStatus: null,
        version: 0,
    }

    useEffect(() => {
        statusService.getStatuses().then(data => {
            setStatusOptions(data)
        })
        if (!isNullOrUndef(paramBookingId) && !isNaN(parseInt(paramBookingId))) {
            setEdit(true)
            getBookingData(paramBookingId)
        } else {
            setBooking(emptyBooking)
            setCheckSum(JSum.digest(emptyBooking, 'SHA256', 'hex'))
            setFieldsValidation(emptyBooking)
            setLoad(true)
        }
    }, [paramBookingId])

    useEffect(() => {
        incotermsService.getIncoterms().then(data => setIncoterms(data));
        transportModeService.getTransportModes().then(data => setTransportModes(data));
        countryDirectionService.getCountryDirections().then(data => setDirections(data));
        shippingLineService.getShippingLines().then(data => setShippingLines(data));
        tradeTypeService.getTradeTypes().then(data => setTradeTypes(data))
        containerTypesService.getContainerTypes().then(data => setContainerTypes(data));
        bookingsService.getBillOfLadingStatuses().then(data => setBillOfLadingStatuses(data));
        bookingsService.getImporterSecurityFilling().then(data => setImporterSecurityFillings(data));
    }, [])

    useEffect(() => {
        if(!isNullOrUndef(booking) && !isNullOrUndef(booking.mhPc) && !isNullOrUndef(booking.mhOc)) {
            validThisForm()
        }
    }, [booking])

    const getBookingData = (bookingId) => {
        bookingsService.getBooking(bookingId).then(data => {
            //NEED for costs items editing
            if (data.costs.length > 0) {
                data.costs.forEach(c => {
                    c['tempId'] = 'Id_' + nextId();
                })
            }
            setBooking(data)
            setCheckSum(JSum.digest(data, 'SHA256', 'hex'))
            if (!isNullOrUndef(paramTab) && !isNaN(parseInt(paramTab))) {
                setActiveIndex(Number(paramTab))
                history.push(`/bookings/view/${bookingId}`);
            }
            bookingsService.getPodUpdateRegister(data.id).then(r => setPodUpdateRegister(r))
            setBookingClientIsEmpty(isNullOrUndef(data.client))
            setBookingDirectionIsEmpty(isNullOrUndef(data.directionId))
            setBookingIncotermsIsEmpty(isNullOrUndef(data.incotermId))
            setLoad(true)
            setFieldsValidation(data)
        })
    }

    const setFieldsValidation = (data) => {
        let currentUserWorking = isNullOrUndef(data.operator) || data.operator.id === props.userData.id
        let transferring = !isNullOrUndef(data.operationalStatus) && data.operationalStatus === "TRANSFERRING"
        setTransferring(transferring)
        setIsClosedIncomeCostTab(!props.checkUserRole('ROLE_ADMIN') && (data.statusId === 4 || data.statusId === 7))
        setIsClosed( !props.checkUserRole('ROLE_ADMIN') &&  [4, 7, 8].includes(data.statusId) || transferring || !currentUserWorking)
        let simpleMode = !isNullOrUndef(data.transportModeId) && [3, 5, 6].includes(Number(data.transportModeId));

        let fields = {}
        switch (data.statusId) {
            case 2: // New booking
                fields = {
                    tradeTypeId: {required: true, disabled: false},
                    client: {required: true, disabled: false},
                    transportModeId: {required: true, disabled: false},
                    directionId: {required: !simpleMode, disabled: false},
                    placeOfLoading: {required: false, disabled: false},
                    placeOfDischarge: {required: false, disabled: false},
                };
                break;
            case 9: // Pre-carriage
                fields = {
                    tradeTypeId: {required: true, disabled: true}, // Imp/Exp
                    client: {required: true, disabled: true}, // Customer
                    transportModeId: {required: true, disabled: true}, // Transport mode
                    directionId: {required: !simpleMode, disabled: !simpleMode}, // Trades
                    number: {required: !simpleMode, disabled: false}, // Booking number
                    incotermId: {required: !simpleMode, disabled: false}, // Incoterms
                    shipper: {required: true, disabled: false}, // Shipper
                    consignee: {required: true, disabled: false}, // Consignee
                    shippingLineId: {required: !simpleMode, disabled: false}, // Shipping line
                    portOfLoading: {required: !simpleMode, disabled: false}, // POL
                    portOfDischarge: {required: !simpleMode, disabled: false}, // POD
                    portOfLoadingDeparture: {required: !simpleMode, disabled: false}, // POL - ETD
                    portOfDischargeArrival: {required: !simpleMode, disabled: false}, // POD - ETA
                    portOfDischargeArrivalNotice: {required: !simpleMode, disabled: false}, // POD - ETA UPDATE
                    placeOfLoading: {required: true, disabled: false}, // PLL
                    placeOfDischarge: {required: simpleMode, disabled: false}, // PLD
                    dateOfLoading: {required: true, disabled: false}, // LD
                    placeOfDischargeArrival: {required: simpleMode, disabled: false}, // UD
                    containerType: {required: !simpleMode, disabled: false}, // Container Type
                    // emptyContainerList: {required: true, disabled: false}, // Container list
                };
                break;
            case 1: // SOB
                fields = {
                    tradeTypeId: {required: true, disabled: true}, // Imp/Exp
                    client: {required: true, disabled: true}, // Customer
                    transportModeId: {required: true, disabled: true}, // Transport mode
                    directionId: {required: true, disabled: true}, // Trades
                    number: {required: true, disabled: true}, // Booking number
                    incotermId: {required: true, disabled: false}, // Incoterms
                    shipper: {required: true, disabled: false}, // Shipper
                    consignee: {required: true, disabled: false}, // Consignee
                    shippingLineId: {required: true, disabled: true}, // Shipping line
                    portOfLoading: {required: true, disabled: true}, // POL
                    portOfDischarge: {required: true, disabled: false}, // POD
                    portOfLoadingDeparture: {required: true, disabled: true}, // POL - ETD
                    portOfDischargeArrival: {required: true, disabled: true}, // POD - ETA
                    portOfDischargeArrivalNotice: {required: true, disabled: false}, // POD - ETA UPDATE
                    placeOfLoading: {required: true, disabled: true}, // PLL
                    placeOfDischarge: {required: false, disabled: false}, // PLD
                    dateOfLoading: {required: true, disabled: true}, // LD
                    placeOfDischargeArrival: {required: false, disabled: false}, // UD
                    containerType: {required: true, disabled: false}, // Container Type
                    mhPc: {required: false, disabled: true}, // Container Type
                };
                break
            case 5: // Arrived
                fields = {
                    tradeTypeId: {required: true, disabled: true}, // Imp/Exp
                    client: {required: true, disabled: true}, // Customer
                    transportModeId: {required: true, disabled: true}, // Transport mode
                    directionId: {required: true, disabled: true}, // Trades
                    number: {required: true, disabled: true}, // Booking number
                    incotermId: {required: true, disabled: false}, // Incoterms
                    shipper: {required: true, disabled: false}, // Shipper
                    consignee: {required: true, disabled: false}, // Consignee
                    shippingLineId: {required: true, disabled: true}, // Shipping line
                    portOfLoading: {required: true, disabled: true}, // POL
                    portOfDischarge: {required: true, disabled: true}, // POD
                    portOfLoadingDeparture: {required: true, disabled: true}, // POL - ETD
                    portOfDischargeArrival: {required: true, disabled: true}, // POD - ETA
                    portOfDischargeArrivalNotice: {required: true, disabled: true}, // POD - ETA UPDATE
                    placeOfLoading: {required: true, disabled: true}, // PLL
                    placeOfDischarge: {required: false, disabled: false}, // PLD
                    dateOfLoading: {required: true, disabled: true}, // LD
                    placeOfDischargeArrival: {required: false, disabled: false}, // UD
                    containerType: {required: true, disabled: false}, // Container Type
                    mhPc: {required: false, disabled: true}, // Container Type
                };
                break
            case 10: // On-carriage
                fields = {
                    tradeTypeId: {required: true, disabled: true}, // Imp/Exp
                    client: {required: true, disabled: true}, // Customer
                    transportModeId: {required: true, disabled: true}, // Transport mode
                    directionId: {required: true, disabled: true}, // Trades
                    number: {required: true, disabled: true}, // Booking number
                    incotermId: {required: true, disabled: false}, // Incoterms
                    shipper: {required: true, disabled: false}, // Shipper
                    consignee: {required: true, disabled: false}, // Consignee
                    shippingLineId: {required: true, disabled: true}, // Shipping line
                    portOfLoading: {required: true, disabled: true}, // POL
                    portOfDischarge: {required: true, disabled: true}, // POD
                    portOfLoadingDeparture: {required: true, disabled: true}, // POL - ETD
                    portOfDischargeArrival: {required: true, disabled: true}, // POD - ETA
                    portOfDischargeArrivalNotice: {required: true, disabled: true}, // POD - ETA UPDATE
                    placeOfLoading: {required: true, disabled: true}, // PLL
                    dateOfLoading: {required: true, disabled: true}, // LD
                    placeOfDischarge: {required: true, disabled: false}, // PLD
                    placeOfDischargeArrival: {required: true, disabled: false}, // UD
                    containerType: {required: true, disabled: false}, // Container Type
                    mhPc: {required: false, disabled: true}, // Container Type
                };
                break
            case 8: // Delivered
                fields = {
                    tradeTypeId: {required: true, disabled: true}, // Imp/Exp
                    client: {required: true, disabled: true}, // Customer
                    transportModeId: {required: true, disabled: true}, // Transport mode
                    directionId: {required: !simpleMode, disabled: true}, // Trades
                    number: {required: !simpleMode, disabled: true}, // Booking number
                    incotermId: {required: !simpleMode, disabled: true}, // Incoterms
                    shipper: {required: true, disabled: true}, // Shipper
                    consignee: {required: true, disabled: true}, // Consignee
                    shippingLineId: {required: !simpleMode, disabled: true}, // Shipping line
                    portOfLoading: {required: !simpleMode, disabled: true}, // POL
                    portOfDischarge: {required: !simpleMode, disabled: true}, // POD
                    portOfLoadingDeparture: {required: !simpleMode, disabled: true}, // POL - ETD
                    portOfDischargeArrival: {required: !simpleMode, disabled: true}, // POD - ETA
                    portOfDischargeArrivalNotice: {required: !simpleMode, disabled: true}, // POD - ETA UPDATE
                    placeOfLoading: {required: true, disabled: true}, // PLL
                    dateOfLoading: {required: true, disabled: true}, // LD
                    placeOfDischarge: {required: false, disabled: true}, // PLD
                    placeOfDischargeArrival: {required: true, disabled: true}, // UD
                    containerType: {required: !simpleMode, disabled: true}, // Container Type
                    mhPc: {required: false, disabled: true}, // MH p/c
                    mhOc: {required: false, disabled: true}, // KM o/c
                };
                break
            default:
                return false;
        }

        setRequiredAndDisabledFields(fields);
    }

    const closeBooking = () => {
        history.push(`/bookings`);
    }

    const lockBooking = () => {
        let getErrField = validThisForm(null, true);
        if(getErrField.length > 0){
            toast.current.show({
                severity: 'error',
                summary: 'Required field or Incorrect enter data',
                detail: getErrField.map(err => err.title).join(", ")
            });
        } else {
            confirmDialog({
                message: `Are you sure you want to close this booking ?`,
                icon: 'pi pi-exclamation-triangle',
                accept: () => bookingsService.lockBooking(booking.id).then(data => {
                    if (data.success) {
                        toast.current.show({
                            severity: 'success',
                            summary: 'Successful',
                            detail: 'Booking closed successfully',
                            life: 3000
                        });
                        getBookingData(booking.id);
                    } else {
                        toast.current.show({
                            severity: 'error',
                            summary: 'Close booking failed',
                            detail: data.errorMessage
                        });
                    }
                }),
                reject: () => null
            });
        }
    }

    const cancelBooking = () => {
        confirmDialog({
            message: `Are you sure you want to cancel this booking ?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => bookingsService.cancelBooking(booking.id).then(data => {
                if(data.success) {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Booking cancelled successfully',
                        life: 3000
                    });
                    getBookingData(booking.id);
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Cancel booking failed',
                        detail: data.errorMessage,
                        life: 3000
                    });
                }
            }),
            reject: () => null
        });
    }

    const showAuditLog = (bookingId) => {
        bookingsService.getAuditLog(bookingId).then(data => {
            setBookingAuditData(data);
            setBookingAuditDialogVisible(true);
        })
    }

    const checkRequiredField = (field) => {
        if (requiredAndDisabledFields[field]) {
            return requiredAndDisabledFields[field].required
        }
        return false
    }

    const checkFieldIsObject = (field) => {
        return isNullOrUndef(booking[field]) || typeof booking[field] !== "object"
    }

    const checkFieldIsDate = (field) => {
        return isNullOrUndef(booking[field]) || !moment(booking[field]).isValid()
    }

    const validMessage = (field) => {
        return errFields.filter(f => f.value === field).length > 0
    }

    const checkDisabledField = (field) => {
        if(props.checkUserRole(['ROLE_ADMIN'])) {
            return false;
        } else if(isClosed) {
            return true
        } else if((field === 'mhOc' || field === 'mhPc') && [3, 5, 6].includes(Number(booking.transportModeId))) {
            return true;
        } else if(!isNullOrUndef(requiredAndDisabledFields[field])) {
            return requiredAndDisabledFields[field].disabled
        } else {
            return false;
        }
    }

    const validThisForm = (editingRowId, close) => {
        setValidForm(true)
        let _errFields = []
        if (checkRequiredField('tradeTypeId') && isNullOrUndef(booking.tradeTypeId)) {
            _errFields.push({title: 'Imp/Exp', value: 'tradeTypeId'})
        }
        if (checkRequiredField('client') && checkFieldIsObject('client')) {
            _errFields.push({title: 'Customer', value: 'client'})
        }
        if (checkRequiredField('number') && (isNullOrUndef(booking.number) || booking.number === '')) {
            _errFields.push({title: 'Booking number', value: 'number'})
        }
        if (checkRequiredField('transportModeId') && isNullOrUndef(booking.transportModeId)) {
            _errFields.push({title: 'Transport mode', value: 'transportModeId'})
        }
        if (checkRequiredField('incotermId') && isNullOrUndef(booking.incotermId)) {
            _errFields.push({title: 'Incoterms', value: 'incotermId'})
        }
        if (checkRequiredField('shipper') && checkFieldIsObject('shipper')) {
            _errFields.push({title: 'Shipper', value: 'shipper'})
        }
        if (checkRequiredField('consignee') && checkFieldIsObject('consignee')) {
            _errFields.push({title: 'Consignee', value: 'consignee'})
        }
        if (checkRequiredField('directionId') && isNullOrUndef(booking.directionId)) {
            _errFields.push({title: 'Trades', value: 'directionId'})
        }
        if (checkRequiredField('forwarder') && checkFieldIsObject('forwarder')) {
            _errFields.push({title: 'Forwarder', value: 'forwarder'})
        }
        if (checkRequiredField('shippingLineId') && isNullOrUndef(booking.shippingLineId)) {
            _errFields.push({title: 'Shipping line', value:'shippingLineId'})
        }
        if (checkRequiredField('customerRefId') && (isNullOrUndef(booking.customerRefId) || booking.customerRefId === '')) {
            _errFields.push({title: 'Customer Ref.', value: 'customerRefId'})
        }
        if (!booking.mhPc && checkRequiredField('placeOfLoading') && checkFieldIsObject('placeOfLoading')) {
            _errFields.push({title: 'PLL', value: 'placeOfLoading'})
        }
        if (!booking.mhOc && checkRequiredField('placeOfDischarge') && checkFieldIsObject('placeOfDischarge')) {
            _errFields.push({title: 'PLD', value: 'placeOfDischarge'})
        }
        if (checkRequiredField('portOfDischarge') && checkFieldIsObject('portOfDischarge')) {
            _errFields.push({title: 'POD', value: 'portOfDischarge'})
        }
        if (checkRequiredField('portOfLoading') && checkFieldIsObject('portOfLoading')) {
            _errFields.push({title: 'POL', value: 'portOfLoading'})
        }
        if (checkRequiredField('portOfLoadingDeparture') && checkFieldIsDate('portOfLoadingDeparture')) {
            _errFields.push({title: 'POL - ETD', value: 'portOfLoadingDeparture'})
        }
        if (checkRequiredField('portOfDischargeArrival') && checkFieldIsDate('portOfDischargeArrival')) {
            _errFields.push({title: 'POD - ETA', value: 'portOfDischargeArrival'})
        }
        if (checkRequiredField('portOfDischargeArrivalNotice') && checkFieldIsDate('portOfDischargeArrivalNotice')) {
            _errFields.push({title: 'POD - ETA UPDATE', value: 'portOfDischargeArrivalNotice'})
        }

        let needCheckEditCargo = false;
        let cargos = [];
        if (booking.cargos.length > 0) {
            cargos = booking.cargos;
            needCheckEditCargo = true;
            if (!isNullOrUndef(editingRowId)) {
                cargos = cargos.filter(c => c.id === editingRowId)
            }
        }

        if (checkRequiredField('containerType')) {
            cargos.forEach(c => {
                if (isNullOrUndef(c.containerType)) {
                    _errFields.push({title: 'Container Type', value: 'containerType'})
                }
            })
        }
        if (!booking.mhPc && checkRequiredField('dateOfLoading')) {
            if (needCheckEditCargo) {
                cargos.forEach(c => {
                    if (isNullOrUndef(c.dateOfLoading)) {
                        _errFields.push({title: 'LD', value: 'dateOfLoading'})
                    }
                })
            } else {
                _errFields.push({title: 'LD', value: 'dateOfLoading'})
            }

        }
        if (!booking.mhOc && checkRequiredField('placeOfDischargeArrival')) {
            if (needCheckEditCargo) {
                cargos.forEach(c => {
                    if (isNullOrUndef(c.placeOfDischargeArrival)) {
                        _errFields.push({title: 'UD', value: 'placeOfDischargeArrival'})
                    }
                })
            } else {
                _errFields.push({title: 'LD', value: 'dateOfLoading'})
            }
        }
        if([3, 5, 6].includes(Number(booking.transportModeId))) {
            if (needCheckEditCargo) {
                cargos.forEach(c => {
                    if (isNullOrUndef(c.dateOfLoading)) {
                        _errFields.push({title: 'LD', value: 'dateOfLoading'})
                    }
                    if (isNullOrUndef(c.placeOfDischargeArrival)) {
                        _errFields.push({title: 'UD', value: 'placeOfDischargeArrival'})
                    }
                })
            } else {
                _errFields.push({title: 'LD', value: 'dateOfLoading'})
                _errFields.push({title: 'UD', value: 'placeOfDischargeArrival'})
            }
        }

        if (isNullOrUndef(booking.incomes)) {
            _errFields.push({title: 'Incomes tab - No Customer', value: 'incomes'})
        } else if (booking.incomes.length > 0) {
            booking.incomes.forEach(inc => {
                if (!isObject(inc.client)) {
                    _errFields.push({title: 'Incomes tab - Incorrect client', value: 'incomes'})
                } else if(!isNullOrUndef(close) && (isNullOrUndef(inc.invoiceStatus) || inc.invoiceStatus === 'PENDING')) {
                    _errFields.push({title: 'Incomes tab - Not completed', value: 'incomes'})
                } else {
                    if (isNullOrUndef(inc.incomeItems) || inc.incomeItems.length === 0) {
                        _errFields.push({title: 'Incomes tab - No entered plan', value: 'incomes'})
                    }
                }
            })
        } else {
            _errFields.push({title: 'Incomes tab - No Customer', value: 'incomes'})
        }

        if (isNullOrUndef(booking.costs)) {
            _errFields.push({title: 'Costs tab - No Vendor', value: 'costs'})
        } else if (booking.costs.length === 0) {
            _errFields.push({title: 'Costs tab - No Vendor', value: 'costs'})
        }

        if(!isNullOrUndef(booking.costs) && booking.costs.length > 0){
            if((!isNullOrUndef(close) || booking.statusId === 8) && booking.costs.some(c => isNullOrUndef(c.invoiceId))) {
                _errFields.push({
                    title: 'Some invoice in "Cost" is empty. Cannot save booking with status CLOSED!',
                    value: 'allCostInvoicesIsNotFilled'
                })
            }
        }

        setErrFields(_errFields)
        return _errFields
    }

    const saveBooking = (type, objId) => {
        setActivatedEdit(true)
        let getErrField = validThisForm();
        if (getErrField.length === 0 || booking.statusId !== 2 || props.checkUserRole(['ROLE_ADMIN'])) {
            setValidForm(false)
            let _booking = prepareBookingToSave()
            bookingsService.saveBooking(_booking).then(data => {
                if (data.success) {
                    toast.current.show({severity: 'success', summary: 'Save', detail: 'Booking is saved'});
                    if (type === 'stay') {
                        setActivatedEdit(false)
                        if (booking.id === "") {
                            history.push(`/bookings/view/${data.data.id}`);
                        } else {
                            getBookingData(data.data.id)
                        }
                        setBookingClientIsEmpty(isNullOrUndef(data.client))
                        setBookingDirectionIsEmpty(isNullOrUndef(data.directionId))
                    } else if (type === 'back') {
                        history.push(`/bookings`);
                    }
                } else {
                    setActivatedEdit(false)
                    toast.current.show({severity: 'error', summary: 'Booking', detail: data.errorMessage});
                }
            })
        } else {
            setActivatedEdit(false)
            toast.current.show({
                severity: 'error',
                summary: 'Required field or Incorrect enter data',
                detail: getErrField.map(err => err.title).join(", ")
            });
        }
    }


    const changeInvoiceData = (invoiceId, value, element) => {
        let _booking = booking
        let idx = findIndexById(invoiceId, _booking.incomes)
        _booking.incomes[idx][element] = value;
        setBooking(booking)
    }

    const findIndexById = (id, obj) => {
        let index = -1;
        for (let i = 0; i < obj.length; i++) {
            if (obj[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const makeShipperConsigneeForwarderObj = (clientObj) => {
        if (clientObj.hasOwnProperty('value')) {
            delete clientObj.value
        }
        clientObj['clientTypeId'] = null
        clientObj['countryId'] = null
        clientObj['userIds'] = []
        clientObj['tradeTypeIds'] = []
        if (!isNullOrUndef(clientObj.clientType)) {
            clientObj['clientTypeId'] = clientObj.clientType.id
        }
        delete clientObj.clientType
        if (!isNullOrUndef(clientObj.country)) {
            clientObj['countryId'] = clientObj.country.id
        }
        delete clientObj.country

        if (!isNullOrUndef(clientObj.users) && clientObj.users.length > 0) {
            clientObj['userIds'] = clientObj.users.map(id => id)
        }
        delete clientObj.users
        if (!isNullOrUndef(clientObj.tradeTypes) && clientObj.tradeTypes.length > 0) {
            clientObj['tradeTypeIds'] = clientObj.tradeTypes.map(id => id)
        }
        delete clientObj.tradeTypes
        if (!isNullOrUndef(clientObj.clientGroups) && clientObj.clientGroups.length > 0) {
            clientObj['clientGroupIds'] = clientObj.clientGroups.map(cg => cg.id)
        }
        delete clientObj.clientGroups

        return clientObj;
    }

    const prepareBookingToSave = () => {
        if (!isNullOrUndef(booking.placeOfDischarge) && !isEmpty(booking.placeOfDischarge)) {
            booking.placeOfDischarge['placeTypeId'] = null
            if (!isNullOrUndef(booking.placeOfDischarge.placeType)) {
                booking.placeOfDischarge['placeTypeId'] = booking.placeOfDischarge.placeType.id
            }
            delete booking.placeOfDischarge.placeType
        } else if (!isNullOrUndef(booking.placeOfDischarge) && isEmpty(booking.placeOfDischarge)) {
            booking.placeOfDischarge = null
        }
        if (!isNullOrUndef(booking.placeOfLoading) && !isEmpty(booking.placeOfLoading)) {
            booking.placeOfLoading['placeTypeId'] = null
            if (!isNullOrUndef(booking.placeOfLoading.placeType)) {
                booking.placeOfLoading['placeTypeId'] = booking.placeOfLoading.placeType.id
            }
            delete booking.placeOfLoading.placeType
        } else if (!isNullOrUndef(booking.placeOfLoading) && isEmpty(booking.placeOfLoading)) {
            booking.placeOfLoading = null
        }
        if (!isNullOrUndef(booking.portOfLoading) && isEmpty(booking.portOfLoading)) {
            booking.portOfLoading = null
        }
        if (!isNullOrUndef(booking.portOfDischarge) && isEmpty(booking.portOfDischarge)) {
            booking.portOfDischarge = null
        }
        if (!isNullOrUndef(booking.shipper) && booking.shipper === '') {
            booking.shipper = null
        } else if (!isNullOrUndef(booking.shipper) && typeof booking.shipper === "object") {
            booking.shipper = makeShipperConsigneeForwarderObj(booking.shipper)
        }

        if (!isNullOrUndef(booking.client) && booking.client === '') {
            booking.client = null
        } else if (!isNullOrUndef(booking.client) && typeof booking.client === "object") {
            booking.client = {id: booking.client.id}
        }

        if (!isNullOrUndef(booking.consignee) && booking.consignee === '') {
            booking.consignee = null
        } else if (!isNullOrUndef(booking.consignee) && typeof booking.consignee === "object") {
            booking.consignee = makeShipperConsigneeForwarderObj(booking.consignee)
        }

        if (!isNullOrUndef(booking.forwarder) && booking.forwarder === '') {
            booking.forwarder = null
        } else if (!isNullOrUndef(booking.forwarder) && typeof booking.forwarder === "object") {
            booking.forwarder = makeShipperConsigneeForwarderObj(booking.forwarder)
        }
        if (booking.cargos.length > 0) {
            booking.cargos.forEach(c => {
                if (!Number.isFinite(c.id)) {
                    c.id = null
                }
            })
        }
        if (booking.cargoItems.length > 0) {
            booking.cargoItems.forEach(ci => {
                if (!Number.isFinite(ci.id)) {
                    ci.id = null
                }
                delete ci.bookings
            })
        }
        if (booking.incomes.length > 0) {
            booking.incomes.forEach(inc => {
                if (!Number.isFinite(inc.id)) {
                    inc.id = null
                }
                if (inc.client === '') {
                    inc.client = null
                }
                if (inc.incomeItems.length > 0) {
                    inc.incomeItems.forEach(incItem => {
                        if (!Number.isFinite(incItem.id)) {
                            incItem.id = null
                        }
                    })
                }
            })
        }
        if (booking.costs.length > 0) {
            booking.costs.forEach(cs => {
                delete cs.tempId
            })
        }
        if (isNullOrUndef(booking.user)) {
            booking.user = {id: props.userData.id};
        }
        return booking
    }

    const checkOtherType = (type) => {
        if (type === 'customsClearance' || type === 'verifiedGrossMass' || type === 'shippingInstruction'
            || type === 'kipis' || type === 'insurance' || type === 'placeOfDischarge' || type === 'placeOfLoading'
            || type === 'shipper' || type === 'consignee' || type === 'client' || type === 'forwarder'
            || type === 'proofOfDelivery') {
            return true
        }
        return false
    }

    const onChangeValue = (data, type) => {
        let value = !isNullOrUndef(data.value) ? data.value : checkOtherType(type) ? data : !isNullOrUndef(data.target.value) ? data.target.value : null
        if(type === 'mhPc') {
            value = data.checked;
            if (booking.statusId === 9) {
                let _requiredAndDisabledFields = {...requiredAndDisabledFields}
                _requiredAndDisabledFields['placeOfLoading'].required = !data.checked;
                _requiredAndDisabledFields['placeOfLoading'].disabled = data.checked;
                setRequiredAndDisabledFields(_requiredAndDisabledFields)
            }
        } else if(type === 'mhOc') {
            value = data.checked;
            if (booking.statusId === 10) {
                let _requiredAndDisabledFields = {...requiredAndDisabledFields}
                _requiredAndDisabledFields['placeOfDischarge'].required = !data.checked;
                _requiredAndDisabledFields['placeOfDischarge'].disabled = data.checked;
                setRequiredAndDisabledFields(_requiredAndDisabledFields)
            }
        } else if(type === 'transportModeId') {
            // FTL id is 5
            // LTL id is 3
            // TRAIN id is 6
            let simpleMode = false;
            if(!isNullOrUndef(value)) {
                simpleMode = [3, 5, 6].includes(Number(value));
            }
            if(booking.statusId === 2) {
                let _requiredAndDisabledFields = {...requiredAndDisabledFields}
                _requiredAndDisabledFields['directionId'].required = !simpleMode;
                setRequiredAndDisabledFields(_requiredAndDisabledFields)
            }
        }
        let _booking = {...booking}
        _booking[type] = value;
        setBooking(_booking)
    }

    const onBookingChange = (obj, type) => {
        let _booking = {...booking}
        _booking[type] = obj;
        setBooking(_booking)
    }

    const warnAboutActiveEdit = () => {
        return toast.current.show({
            severity: 'warn',
            summary: 'Edit',
            detail: 'Some edit is activated! Close active edit. '
        });
    }

    const hideAuditLogDialog = () => {
        setBookingAuditDialogVisible(false);
    }

    const openTransferBookingDialog = (transfer) => {
        if(JSum.digest(booking, 'SHA256', 'hex') !== checkSum) {
            toast.current.show({
                severity: 'warn',
                summary: 'Save booking',
                detail: 'Some data are changed! Please save booking first.'
            })
        } else {
            setBookingTransferDialogHeader(transfer ? 'Transfer to operator' : 'Reject transfer to operator')
            setActionTransfer(transfer)
            setBookingTransferDialogVisible(true)
        }
    }

    const rejectTransferring = (comment) => {
        let body = {
            comment: comment,
            bookingVersion: booking.version
        }
        bookingsService.rejectTransfer(booking.id, body)
            .then(d => {
                if (d.success) {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Operator transfer was rejected'
                    });
                    hideBookingTransferDialog()
                    getBookingData(booking.id)
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: d.errorMessage + ' Operator transfer was not rejected!'
                    });
                }
            }).catch(error => {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: error + 'Please contact to your administrator!',
                life: 3000
            });
        });
    }

    const hideBookingTransferDialog = () => {
        setBookingTransferDialogVisible(false)
        setBookingTransferDialogHeader('')
    }

    const transferBooking = (managerId, comment, keepStatus, keepOperator) => {
        let body = {
            nextOperator: {id: managerId},
            keepStatus: keepStatus,
            keepOperator: keepOperator,
            comment: comment,
            bookingVersion: booking.version
        }
        bookingsService.initiateTransfer(booking.id, body)
            .then(d => {
                if (d.success) {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Operator transferred'
                    });
                    hideBookingTransferDialog()
                    getBookingData(booking.id)
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: d.errorMessage + ' Operator not transferred!'
                    });
                }
            }).catch(error => {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: error + 'Please contact to your administrator!',
                life: 3000
            });
        });
    }

    const cancelTransferBooking = () => {
        let body = {
            bookingVersion: booking.version
        }
        bookingsService.cancelTransfer(booking.id, body)
            .then(d => {
                if (d.success) {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Operator transfer is canceled'
                    });
                    getBookingData(booking.id)
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: d.errorMessage + ' Operator transfer not canceled!'
                    });
                }
            }).catch(error => {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: error + 'Please contact to your administrator!',
                life: 3000
            });
        });
    }

    const acceptTransferring = () => {
        let body = {
            bookingVersion: booking.version
        }
        bookingsService.acceptTransfer(booking.id, body)
            .then(d => {
                if (d.success) {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Transferring is accepted'
                    });
                    getBookingData(booking.id)
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: d.errorMessage + ' Transferring is not accepted!'
                    });
                }
            }).catch(error => {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: error + 'Please contact to your administrator!',
                life: 3000
            });
        });
    }

    const bookingTitle = () => {
        let editBookingTitle = 'Edit booking'
        if (transferring) {
            editBookingTitle = `Edit booking - is being transferred to operator ${booking.nextOperator.firstName} ${booking.nextOperator.lastName}`
        }
        return edit ? editBookingTitle : 'Add new booking'
    }

    const loadBookingManagerInfo = () => {
        if(isNullOrUndef(booking.id))
            return
        if(!isNullOrUndef(booking.manager)){
            return booking.manager.firstName + ' ' + booking.manager.lastName
        } else {
            let errorInfo = 'Must select Account, Direction and Incoterms!'
            if(!bookingClientIsEmpty && (!bookingDirectionIsEmpty || !bookingIncotermsIsEmpty)){
                if(!isNullOrUndef(booking.client)){
                    errorInfo = `Check managers in selected Account (${booking.client.title}) trades!`
                } else {
                    errorInfo = `Must select Account, Trades and Incoterms!`
                }

            }
            return <i className={'pi pi-exclamation-circle'} title={errorInfo}
                      onMouseEnter={() => setCheckManagerError(true)}
                      onMouseLeave={() => setCheckManagerError(false)}
                      style={{color: '#D32F2F', marginLeft: '15px', fontSize: '1.2rem'}}/>
        }
    }

    return load ? <div className="card">
        <Toast ref={toast}/>
        <ConfirmDialog/>
        <h5 style={{marginTop: '0'}}>{bookingTitle()}</h5>
        <div className="card" style={{marginBottom: '10px'}}>
            <div className="flex card-container indigo-container">
                <div className="flex-1 h-2rem bg-indigo-500 p-1">
                    <b>RefId:</b> <br/>
                    {!isNullOrUndef(booking.id) && booking.id !== "" ? booking.id : ''}
                </div>
                <div className="flex-1 h-2rem bg-indigo-500 p-1">
                    <b>Booking ID:</b> <br/>
                    {!isNullOrUndef(booking.bookingId) ? booking.bookingId : ''}
                </div>
                <div className="flex-1 h-2rem bg-indigo-500 p-1">
                    <b>Manager:</b> <br/>
                    {loadBookingManagerInfo()}
                </div>
                <div className="flex-1 h-2rem bg-indigo-500 p-1">
                    <b>Operator:</b> <br/>
                    {!isNullOrUndef(booking.operator) ? booking.operator.firstName + ' ' + booking.operator.lastName : ''}
                </div>
                <div className="flex-1 h-2rem bg-indigo-500 p-1">
                    <b>Last edit:</b> <br/>
                    {moment(booking.updatedAt).format('YYYY-MM-DD HH:MM')}
                </div>
                <div className="flex-1 h-2rem bg-indigo-500 p-1">
                    <b>Plan:</b> <br/>
                    <span title={'Plan'}><u>{booking.plan}</u></span>
                    <span title={'initial Plan'}>({booking.initialPlan})</span> {props.defaultCurrency.code}
                </div>
                <div className="flex-1 h-2rem bg-indigo-500 p-1">
                    <b>Real:</b> <br/>
                    {booking.real} {props.defaultCurrency.code}
                </div>
            </div>
        </div>
        <TabView activeIndex={activeIndex}
                 onTabChange={(e) => !activatedEdit ? setActiveIndex(e.index) : warnAboutActiveEdit()}>
            <TabPanel header="Info">
                <InfoTab booking={booking} statuses={statuses} directions={directions} tradeTypes={tradeTypes}
                         containerTypes={containerTypes} currencies={props.currencies} incoterms={incoterms}
                         validForm={validForm} setValidForm={setValidForm} setActivatedEdit={setActivatedEdit}
                         importerSecurityFillings={importerSecurityFillings} transportModes={transportModes}
                         toast={toast} billOfLadingStatuses={billOfLadingStatuses} activatedEdit={activatedEdit}
                         statusOptions={statusOptions} onChangeValue={onChangeValue} shippingLines={shippingLines}
                         isClosed={isClosed} podUpdateRegister={podUpdateRegister} validMessage={validMessage}
                         checkDisabledField={checkDisabledField} validThisForm={validThisForm}
                         checkUserRole={props.checkUserRole}
                         checkManagerError={checkManagerError} requiredAndDisabledFields={requiredAndDisabledFields}
                         bookingClientIsEmpty={bookingClientIsEmpty} bookingDirectionIsEmpty={bookingDirectionIsEmpty}
                         bookingIncotermsIsEmpty={bookingIncotermsIsEmpty} checkCostInvoices={checkCostInvoices}
                         warnAboutActiveEdit={() => warnAboutActiveEdit} clientGroups={props.clientGroups}
                />
            </TabPanel>
            <TabPanel header="Income">
                <IncomeTab booking={booking} changeInvoiceData={changeInvoiceData} setBooking={setBooking}
                           defaultCurrency={props.defaultCurrency} checkSum={checkSum}
                           getClientGroupId={props.getClientGroupId}
                           checkUserRole={props.checkUserRole} isClosedIncomeCostTab={isClosedIncomeCostTab}
                           validForm={validForm} setValidForm={setValidForm} activatedEdit={activatedEdit}
                           setActivatedEdit={setActivatedEdit} currencies={props.currencies} toast={toast}
                           warnAboutActiveEdit={() => warnAboutActiveEdit} onBookingChange={onBookingChange}/>
            </TabPanel>
            <TabPanel header="Cost">
                <CostTab booking={booking} currencies={props.currencies} validForm={validForm}
                         isClosedIncomeCostTab={isClosedIncomeCostTab} getClientGroupId={props.getClientGroupId}
                         defaultCurrency={props.defaultCurrency} checkUserRole={props.checkUserRole}
                         warnAboutActiveEdit={() => warnAboutActiveEdit()} toast={toast} checkSum={checkSum}
                         setValidForm={setValidForm} activatedEdit={activatedEdit} setActivatedEdit={setActivatedEdit}/>
            </TabPanel>
            <TabPanel header="Attachments" disabled={isNullOrUndef(booking.id)}>
                <AttachTab booking={booking} validForm={validForm} setValidForm={setValidForm}
                           warnAboutActiveEdit={() => warnAboutActiveEdit()} tost={toast} isClosed={isClosed}
                           activatedEdit={activatedEdit} setActivatedEdit={setActivatedEdit}/>
            </TabPanel>
        </TabView>
        <div className={"p-d-flex p-jc-between p-flex-row"}>
            <div>
                {!isNullOrUndef(booking.operator) && !isNullOrUndef(props.userData) && props.userData.id === booking.operator.id
                && booking.statusId !== 7 && booking.statusId !== 4 ?
                <Button label="Cancel booking" icon={'pi pi-times-circle'}
                        onClick={() => cancelBooking()} className={'p-button-danger mr-2 mb-2'}/> : ''}
                {!isNullOrUndef(booking.operator) && booking.statusId === 8 && props.userData.id === booking.operator.id
                && booking.statusId !== 7 && booking.statusId !== 4 ?
                <Button label="Close booking" icon={'pi pi-lock'}
                        onClick={() => lockBooking()} className={'p-button-warning mr-2 mb-2'}/> : ''}
            </div>
            <div>
                {transferring ? <div style={{margin: "0 15px 20px 0", fontSize: "1.2rem", fontWeight: "bold"}}>
                    Transfer comment: {!isNullOrUndef(booking.operationComment) ? booking.operationComment : ''}
                </div> : ''}
                {!transferring && !isNullOrUndef(booking.operationComment) && booking.operationComment.length !== 0
                    ? <div style={{margin: "0 15px 20px 0", fontSize: "1.2rem", fontWeight: "bold"}}>
                        Transfer comment: {booking.operationComment}
                    </div> : ''}
                <Button label="Save & Edit" icon={'pi pi-save'} disabled={activatedEdit || isClosedIncomeCostTab}
                        onClick={() => saveBooking('stay')} className="p-button-success mr-2 mb-2"/>

                <Button label="Save & Back" icon={'pi pi-history'} disabled={activatedEdit || isClosedIncomeCostTab}
                        onClick={() => saveBooking('back')} className="p-button-warning mr-2 mb-2"/>

                <Button label="Close & Don't Save" icon={'pi pi-ban'} disabled={activatedEdit}
                        onClick={() => closeBooking()} className={'mr-2 mb-2'}/>

                <Button label="Transfer to operator" icon={<i className="fa-solid fa-people-arrows p-button-icon-left"></i>}
                        disabled={activatedEdit || isNullOrUndef(booking.id)}
                        visible={!transferring && (props.checkUserRole(['ROLE_ADMIN']) || (!isNullOrUndef(booking.operator) ? props.userData.id === booking.operator.id : false))}
                        onClick={() => openTransferBookingDialog(true)} className={'p-button-info mr-2 mb-2'}/>

                <Button label="Accept" icon={'pi pi-save'}
                        visible={transferring && (props.checkUserRole(['ROLE_ADMIN']) || (!isNullOrUndef(booking.nextOperator) ? props.userData.id === booking.nextOperator.id : false))}
                        onClick={() => acceptTransferring()} className="p-button-success mr-2 mb-2"/>

                <Button label="Reject" icon={'pi pi-ban'}
                        visible={transferring && (props.checkUserRole(['ROLE_ADMIN']) || (!isNullOrUndef(booking.nextOperator) ? props.userData.id === booking.nextOperator.id : false))}
                        onClick={() => openTransferBookingDialog(false)} className={'p-button-danger mr-2 mb-2'}/>

                <Button label="Cancel transfer to operator"
                        icon={<span className="fa-stack small p-button-icon-left">
                        <i className="fa-solid fa-people-arrows fa-stack-1x"></i>
                        <i className="fa-solid fa-ban fa-stack-2x"></i></span>}
                        visible={transferring && (props.checkUserRole(['ROLE_ADMIN']) || (!isNullOrUndef(booking.operator) ? props.userData.id === booking.operator.id : false))}
                        onClick={() => cancelTransferBooking()} className={'p-button-info mr-2 mb-2'}/>

                <Button label="Log" icon={'pi pi-list'} visible={props.checkUserRole(['ROLE_SUPER_ADMIN'])}
                        onClick={() => showAuditLog(booking.id)} className={'p-button-secondary mr-2 mb-2'}/>
            </div>
        </div>
        <div style={{textAlign: 'right', color: '#999', opacity: '0.3'}}>Version: {booking.version}</div>
        <Dialog visible={bookingAuditDialogVisible} onHide={hideAuditLogDialog}
                header={`Booking ${booking.id} log`}>
            <AuditLog object={bookingAuditData}></AuditLog>
        </Dialog>
        <Dialog visible={bookingTransferDialogVisible} onHide={hideBookingTransferDialog}
                header={bookingTransferDialogHeader} style={{width: '30vw'}}
                breakpoints={{'960px': '50vw', '640px': '75vw'}}>
            <BookingTransferModule booking={booking} transfer={actionTransfer} transferBooking={transferBooking}
                                   errFields={errFields} toast={toast}
                                   rejecttransferBooking={rejectTransferring} cancel={hideBookingTransferDialog}/>
        </Dialog>
    </div> : ''


}